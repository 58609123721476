@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Poppins:wght@400&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.caps{
  font-size: 11px;
  text-transform: uppercase;
  display: block;
  opacity: 0.7;
  letter-spacing: 0.01em;
}

.action{
  border: 2px solid #31E563;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.2em 1em;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  margin: 10px;
  display: inline-block;
  &:hover{
    background: #F1FFEB;
  }
}
