.table{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  min-width: 760px;
  @media(max-width: 760px){
    display: none;
  }
}

.mobile-message{
  text-align: center;
  margin-top: 45vh;
  @media(min-width: 761px){
    display: none;
  }
}

.seats{
  padding-top: 140px;
  display: flex;
  align-items: center;
}

.seats--preview{
  position: relative;
  min-width: 400px;
  text-align: center;
  > div{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .player__badge{
    margin: 0 10px;
    cursor: auto;
    display: inline-block;
  }
  p{
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.player{
  width: 120px;
  padding: 0 20px;
  text-align: center;
  position: relative;
  &__badge{
    border: 2px solid;
    border-radius: 99px;
    display: inline-block;
    padding: 1em;
    text-align: center;
    margin: 8px 0;
    line-height: 0;
    cursor: help;
  }
  &__message{
    position: absolute;
    top: -80px;
    width: 80%;
    left: 10%;
    background: #CBFFDA;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;
    font-size: 15px;
    border-radius: 5px;
    &:after{
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      background: #CBFFDA;
      border-radius: 2px;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
    }
  }
  &__bet{
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
  }
  &__wallet{
    position: absolute;
    top: 55px;
    left: 10px;
    right: 10px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    background: #f4f4f4;
    z-index: 10;
    padding: 10px;
    font-size: 15px;
    box-sizing: border-box;
    span{
      padding-top: 0.7em;
    }
  }
  &__hand{
    font-size: 12px;
    position: absolute;
    left: -200px;
    right: -200px;
    top: -170px;
    transform: scale(0.5);
  }
}

.dash{
  padding-top: 40px;
  padding-bottom: 160px;
  &__hand{
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%) scale(1.4);
    transform-origin: center;
    transition: opacity 0.3s;
  }
  &__wallet{
    position: fixed;
    bottom: 0px;
    left: 50%;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    background: #f4f4f4;
    z-index: 10;
    padding: 15px 20px;
    font-size: 15px;
    box-sizing: border-box;
    transform: translateX(230px);
    text-align: center;
    span{
      padding-top: 0.7em;
    }
  }
  &__actions{
    text-align: center;
    margin-bottom: 50px;
  }
  &__message{
    width: 420px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  &__snooze{
    border-radius: 99px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    padding: 5px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: fixed;
    left: 20px;
    bottom: 20px;
    span{
      transform: translate(2px, 2px);
    }
  }
  &__pot{
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
  }
}

.draw{
  margin-top: 7vh;
}

.entry-form{
  text-align: center;
  padding-top: 50px;
  input{
    border: 2px solid;
    border-radius: 99px;
    padding: 0.2em 1em;
    font-family: inherit;
    font-size: 16px;
    width: 50px;
    text-align: center;
    &:focus{
      outline: none;
    }
  }
  .action{
    margin-top: 30px;
  }
}

.emojis{
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  max-width: 800px;
  overflow-y: scroll;
  margin-left: 20%;
  height: 250px;
  border: 2px solid;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  div{
    box-sizing: border-box;
    display: block;
    border: 2px solid #ffffff;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &:hover, &.selected{
      border: 2px solid;
    }
    span{
      display: block;
      transform: translate(5%, -5%);
    }
  }
}

.dash__message .player__badge{
  cursor: default;
  margin-right: 10px;
}
