.card{
  width: 100px;
  height: 150px;
  border: 5px solid;
  position: relative;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  font-family: 'Lora', serif;
  margin: 10px;
  background: #ffffff;
  transition: transform 0.8s, background-color 0.1s 0.2s, color 0.1s 0.2s;
  &--h, &--d{
    color: #FF0000;
  }
  &--flipped{
    transform: rotateY(180deg);
    background: #E3F8FF;
    color: #ffffff;
  }
  > div{
    opacity: 1;
    transition: opacity 0.2s 0.2s;
    height: 10px;
    width: 10px;
  }
  img{
    position: absolute;
    width: 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  span{
    position: absolute;
    &:first-child{
      top: 2%;
      left: 6%;
    }
    &:last-child{
      bottom: 2%;
      right: 6%;
    }
  }
}

.card--flipped > div{
  opacity: 0;
}
